import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import "@fontsource/syncopate/400.css";
import "@fontsource/syncopate/700.css";
import Content from "./Content";

const DelegatePassTab = () => {
  return (
    <>
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box mb={12} mt={8}>
            <Typography
              fontFamily={"Syncopate"}
              variant="h5"
              textTransform={"lowercase"}
              fontWeight={700}
              align="center"
              sx={{
                maxWidth: 880,
              }}
            >
              Download your invoice and pay your submissions
            </Typography>
            <Typography
              fontFamily={"Syncopate"}
              variant="subtitle1"
              align="center"
            >
              You are able to get an invoice and pay your submissions below
            </Typography>
          </Box>
        </Box> */}

        <Box sx={{ display: "flex", justifyContent: "center" }} py={4}>
          <Card
            sx={{
              minWidth: 275,
              backgroundColor: "#f5f5f5",
              padding: "2rem",
              maxWidth: 880,
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                fontWeight={700}
                mb={4}
                fontFamily={"Syncopate"}
              >
               {"Make online payment (tickets/submissions)".toUpperCase()}
              </Typography>
              <Typography>
                By clicking "Make a payment" you will be transferred to the
                online ticketing platform "Fienta", where you are able to pay
                online for tickets and submissions.
              </Typography>
              {/* <ol>
                <li>
                  <Typography>
                    <b style={{ color: "#ff8562" }}>Pay the application</b> form
                    <u>with the reference code</u> you get after you register
                    your application
                  </Typography>
                </li>
                <li>
                  <Typography>
                    Pay the delegate pass for those who have applied for the
                    awards
                  </Typography>
                </li>
                <li>
                  <Typography>
                    By the standard event tickets (for those who have not
                    applied but want to participate in the event and see
                    presentations from the best agencies)
                  </Typography>
                </li>
                <li>
                  <Typography>
                    By additional products from the event you could be
                    interested in
                  </Typography>
                </li>
              </ol> */}
            </CardContent>
            <CardActions>
              <Button
                href="https://fienta.com/amber-event-awards"
                target={"_blank"}
                size="large"
                variant="contained"
                sx={{
                  backgroundColor: "#000",
                  padding: "1rem 4rem",
                  ":hover": { backgroundColor: "#333" },
                }}
              >
                <Typography fontFamily={"Syncopate"} fontWeight={700}>
                  MAKE A PAYMENT
                </Typography>
              </Button>
            </CardActions>
          </Card>
        </Box>
    </>
  );
};

export default DelegatePassTab;
