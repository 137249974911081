import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const InfoCommon = () => {
  return (
    <Grid container justifyContent={"center"} mt={10}>
      <Grid item xs={12} md={10} lg={8}>
        <Box display={"flex"} xs={{ width: "100%" }} sm={{ width: "50%" }}>
          <InfoOutlinedIcon
            fontSize="large"
            sx={{ mr: "1rem", color: "#505050" }}
          />
          <Typography color={"#505050"} fontStyle={"italic"} variant="caption">
            (!) In case of any questions, you can always contact us on{" "}
            <a
              style={{ color: "#ff8562" }}
              href="mailto:info@beic.pro"
              target={"_blank"}
            >
              info@beic.pro
            </a>
            , <a href="https://www.facebook.com/bneforum">Facebook</a> or{" "}
            <a href="https://wa.me/436645169707">whats'App</a> and you should
            have an answer in almost no time.
            <br />
            We are able to make an invoice in any currency upon your request.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default InfoCommon;
