import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import "@fontsource/syncopate/400.css";
import "@fontsource/syncopate/700.css";

const PricesTable = () => {
  const prices = [
    {
      id: 1,
      name: "Standard Ticket (2 days event)",
      earlyPrice: 199,
      price: 230,
    },
    {
      id: 2,
      name: "One day Ticket",
      earlyPrice: 150,
      price: 180,
    },
    {
      id: 3,
      name: "1 submission for Amber Prize",
      earlyPrice: 220,
      price: 300,
    },
    {
      id: 4,
      name: "3 submissions for Amber Prize",
      earlyPrice: 399,
      price: 620,
    },
    {
      id: 5,
      name: "Delegate pass for applicants",
      earlyPrice: 149,
      price: 175,
    },
    {
      id: 6,
      name: "Students pass",
      earlyPrice: 35,
      price: 35,
    },
  ];
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      
      py={4}
      sx={{backgroundColor: "#f5f5f5"}}
    >
      <Box maxWidth={880}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {/* <TableCell align="center">
                  <Typography
                    fontFamily={"Syncopate"}
                    variant="body2"
                    fontWeight={700}
                  >
                    Early Bird before Sept. 30
                  </Typography>
                </TableCell> */}
                <TableCell align="center">
                  <Typography fontFamily={"Syncopate"} variant="body2">
                    Standard Price
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prices &&
                prices.map((res) => {
                  return (
                    <TableRow
                      key={res.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        background: res.id % 2 ? "#f5f5f5" : "white",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography fontFamily={"Syncopate"} variant="body2">
                          {res.name}
                        </Typography>
                      </TableCell>
                      {/* <TableCell align="center">
                        <Typography
                          fontFamily={"Syncopate"}
                          variant="body2"
                          fontWeight={700}
                        >
                          {res.earlyPrice}
                        </Typography>
                      </TableCell> */}
                      <TableCell align="center">
                        <Typography fontFamily={"Syncopate"} variant="body2">
                          {res.price}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PricesTable;
