import { Container } from "@mui/material";
import Content from "./components/Content";
import Evalato from "./components/Evalato";
import InvoicePay from "./components/InvoicePay";
import NavTabs from "./components/NavTabs";
import Box from "@mui/material/Box";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { useState } from "react";
import InfoCommon from "./components/InfoCommon";
import DelegatePassTab from "./components/DelegatePassTab";
import PricesTable from "./components/PricesTable";

function App() {
  const [tab, setTab] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <>
      <Header />
      {/* <NavTabs state={tab} func={handleChange} /> */}
      {tab === 0 ? (
        <>
            <PricesTable />
          {/* <Content> */}
          <DelegatePassTab />
            <Evalato />
          {/* </Content> */}
          <Content width="70%">
            <InvoicePay />
          </Content>
        </>
      ) : (
        <>
        </>
      )}
      <InfoCommon />
      <Box my="4rem" />
      <Footer />
    </>
  );
}

export default App;
