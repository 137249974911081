import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";

const Evalato = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
      }}
      py={4}
    >
      <Card
        sx={{
          minWidth: 275,
          backgroundColor: "#fff",
          padding: "2rem",
          maxWidth: 880,
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            fontWeight={700}
            mb={4}
            fontFamily={"Syncopate"}
          >
            {"Submit your event to the Amber Prize".toUpperCase()}
          </Typography>
          <Typography>
            If you want to submit your project for the online competition, you
            have to make a few simple steps:
          </Typography>
          <ol>
            <li>
              <Typography>
                <b>Register your event</b> at Evalato platform (by pushing
                "SUBMIT" button you will be transferred to "Evalato", our
                partner platform. You have to go through the whole registration
                process (2 min) and click the "FINALIZE" button. Pay attention
                to the registration ID you get after the registration. Only
                after you make your payment and referring to your Registration
                ID, you'll be able to access your registration details.
              </Typography>
            </li>
            <li>
              <Typography>
                <b style={{ color: "#ff8562" }}>Pay for your submission.</b> You
                can make it in 3 ways: download the invoice below, pay on our
                credentials or pay it via{" "}
                <a href="https://fienta.com/amber-event-awards">
                  Fienta ticketing online
                </a>
                . Just pick up “1 submission” or “3 submissions”, enter the
                Registration ID and after maximum 24 hours the application form
                will be opened for you.
              </Typography>
            </li>
            <li>
              <Typography>
                <b>Access the registration and finalize your submission.</b>
                After we receive your payment, you will be able to fill in the
                form and finalize your submission.{" "}
                <u>
                  Please, do not forget to click «Confirm» button after the
                  submission form is completed.
                </u>
              </Typography>
            </li>
          </ol>
        </CardContent>
        <CardActions>
          <Button
            href="https://pro.evalato.com/4053"
            size="large"
            variant="contained"
            sx={{
              backgroundColor: "#000",
              padding: "1rem 4rem",
              ":hover": { backgroundColor: "#333" },
            }}
          >
            <Typography fontFamily={"Syncopate"} fontWeight={700}>
              Submit Event
            </Typography>
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default Evalato;
