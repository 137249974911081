import React, { useState } from "react";
import "@fontsource/syncopate/400.css";
import "@fontsource/syncopate/700.css";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import BuyButton from "./BuyButton";
import TicketCounter from "./TicketCounter";
import CheckoutDialog from "./CheckoutDialog";
import { euroFormat } from "./euroFormat";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";

const InvoicePay = () => {
  const price1 = 300;
  const price2 = 620;
  const [isLoading, setIsLoading] = useState(false);
  const [discount, setDiscount] = useState(1);
  const [promoCode, setPromoCode] = useState("");
  const [ticket1, setTicket1] = useState(
    parseInt(localStorage.getItem("ticket1")) || null
  );
  const [ticket2, setTicket2] = useState(
    parseInt(localStorage.getItem("ticket2")) || null
  );
  const [open, setOpen] = useState(false);

  const applyPromo = async (promo) => {
    setIsLoading(true);
    const urlDev =
      "http://localhost:5001/amberprize-3f613/us-central1/promoCode";
    const url =
      "https://us-central1-amberprize-3f613.cloudfunctions.net/promoCode";
    const res = await axios.post(url, {}, { headers: { promo } });
    setDiscount(res.data);
    setIsLoading(false);
  };

  const handleTicket1 = (value) => {
    setTicket1(value);
    localStorage.setItem("ticket1", value);
  };

  const handleTicket2 = (value) => {
    setTicket2(value);
    localStorage.setItem("ticket2", value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box py={4}>
      <Box mb={12}>
        <Typography
          fontFamily={"Syncopate"}
          variant="h5"
          textTransform={"lowercase"}
          fontWeight={700}
          align="center"
        >
          Download the invoice for submissions
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography
            fontFamily={"Syncopate"}
            variant="h5"
            textTransform={"lowercase"}
            fontWeight={700}
          >
            One submission to the Amber Prize
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} justifyContent={"end"}>
            <Grid item>
              <Typography
                fontFamily={"Syncopate"}
                variant="h5"
                textTransform={"lowercase"}
                fontWeight={700}
              >
                {euroFormat(price1)}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Typography
                alignItems={"baseline"}
                fontFamily={"Syncopate"}
                variant="h5"
                textTransform={"lowercase"}
                fontWeight={700}
                color={"#ccc"}
                sx={{ textDecorationLine: "line-through" }}
              >
                {euroFormat(300)}
              </Typography>
            </Grid> */}
            <Grid item>
              {ticket1 ? (
                <TicketCounter
                  state={parseInt(ticket1)}
                  func={handleTicket1}
                  unityPrice={price1}
                />
              ) : (
                <BuyButton setState={handleTicket1} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box my={8} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography
            fontFamily={"Syncopate"}
            variant="h5"
            textTransform={"lowercase"}
            fontWeight={700}
          >
            3 submissions to the Amber Prize
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} justifyContent={"end"}>
            <Grid item>
              <Typography
                fontFamily={"Syncopate"}
                variant="h5"
                textTransform={"lowercase"}
                fontWeight={700}
              >
                {euroFormat(price2)}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Typography
                alignItems={"baseline"}
                fontFamily={"Syncopate"}
                variant="h5"
                textTransform={"lowercase"}
                fontWeight={700}
                color={"#ccc"}
                sx={{ textDecorationLine: "line-through" }}
              >
                {euroFormat(620)}
              </Typography>
            </Grid> */}
            <Grid item>
              {ticket2 ? (
                <TicketCounter
                  state={parseInt(ticket2)}
                  func={handleTicket2}
                  unityPrice={price2}
                />
              ) : (
                <BuyButton setState={handleTicket2} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {ticket1 || ticket2 ? (
        <>
          <Grid
            container
            justifyContent={"end"}
            alignItems={"center"}
            spacing={4}
            mt={2}
          >
            <Grid item>
              <TextField
                margin="dense"
                id="promo"
                label="Promo code"
                type="text"
                variant="outlined"
                value={promoCode}
                onChange={(e) => {
                  setPromoCode(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                loading={isLoading}
                loadingPosition="start"
                color="secondary"
                variant="contained"
                onClick={() => applyPromo(promoCode)}
              >
                Apply
              </LoadingButton>
            </Grid>
          </Grid>
          {discount < 1 ? (
            <Grid container justifyContent={"end"} alignItems={"center"} mt={1}>
              <Grid item>
                <Box
                  onClick={() => {
                    setDiscount(1);
                    setPromoCode("");
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    fontStyle={"italic"}
                    color={"#505050"}
                    sx={{
                      ":hover": {
                        textDecorationLine: "line-through",
                        cursor: "pointer",
                      },
                    }}
                  >{`${Math.round(
                    (1 - discount) * 100
                  )}% promo is applied`}</Typography>
                </Box>
              </Grid>
            </Grid>
          ) : null}
          <Grid container mt={6} justifyContent={"end"}>
            <Grid item>
              {discount < 1 ? (
                <Box display={"flex"}>
                  <Typography
                    fontFamily={"Syncopate"}
                    fontWeight={700}
                    variant="h6"
                  >
                    TOTAL:&nbsp;
                  </Typography>
                  <Typography
                    alignItems={"baseline"}
                    fontFamily={"Syncopate"}
                    variant="h5"
                    textTransform={"lowercase"}
                    fontWeight={700}
                    color={"#ccc"}
                    sx={{ textDecorationLine: "line-through" }}
                  >
                    {`${euroFormat(ticket1 * price1 + ticket2 * price2)}`}
                  </Typography>
                  <Typography
                    fontFamily={"Syncopate"}
                    fontWeight={700}
                    variant="h6"
                  >
                    &nbsp;
                    {`${euroFormat(
                      (ticket1 * price1 + ticket2 * price2) * discount,
                      2
                    )}`}
                  </Typography>
                </Box>
              ) : (
                <Typography
                  fontFamily={"Syncopate"}
                  fontWeight={700}
                  variant="h6"
                >
                  {`TOTAL: ${euroFormat(
                    (ticket1 * price1 + ticket2 * price2) * discount
                  )}`}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Button
                onClick={handleClickOpen}
                size="large"
                variant="contained"
                sx={{
                  backgroundColor: "#000",
                  padding: "1rem 4rem",
                  ":hover": { backgroundColor: "#333" },
                }}
              >
                <Typography>Checkout</Typography>
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}
      <CheckoutDialog
        open={open}
        handleClose={handleClose}
        qt1={ticket1}
        qt2={ticket2}
        discount={discount}
      />
    </Box>
  );
};

export default InvoicePay;
