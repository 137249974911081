import { Box, Grid, Link, Typography } from "@mui/material";
import React from "react";
import visit from "../assets/img/visit.png";
import LinkFooter from "./Footer/LinkFooter";
import "@fontsource/syncopate/400.css";
import "@fontsource/syncopate/700.css";

const Footer = () => {
  return (
    <Box color={"#fff"} bgcolor={"#020a17"}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <img src={visit} width={260} height={181} alt="image" />
        </Grid>
        <Grid item xs={12} md={6} py={11.25} px={4}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="start"
              >
                <LinkFooter href="https://amberprize.com/idea" label="idea" />
                <LinkFooter
                  href="https://amberprize.com/agenda"
                  label="agenda"
                />
                <LinkFooter
                  href="https://amberprize.com/story"
                  label="OUR STORY"
                />
                <LinkFooter
                  href="https://amberprize.com/contacts"
                  label="contacts"
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="start"
              >
                <LinkFooter
                  href="https://tickets.amberprize.com/"
                  label="tickets"
                />
                <LinkFooter
                  href="https://amberprize.com/travel"
                  label="visit estonia"
                />
                <LinkFooter
                  href="https://amberprize.com/partners"
                  label="partners"
                />
                <LinkFooter
                  href="https://amberprize.com/policy"
                  label="privacy policy"
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="start"
              >
                <Typography
                  mb={1.25}
                  variant="body2"
                  fontFamily={"Syncopate"}
                  fontWeight={700}
                >
                  FOLLOW US
                </Typography>
                <LinkFooter
                  href="https://www.facebook.com/bneforum/"
                  label="facebook"
                  target="_blank"
                />
                <LinkFooter
                  href="https://www.linkedin.com/company/baltic-nordic-event-forum"
                  label="linkedin"
                  target="_blank"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
