import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import DeleteIcon from "@mui/icons-material/Delete";
import { euroFormat } from "./euroFormat";



const TicketCounter = ({ state = 1, func, unityPrice }) => {
  return (
    <>
      <Box display={"flex"} alignItems={"center"}>
        <IconButton onClick={() => func(state - 1)}>
          <RemoveCircleOutlineOutlinedIcon />
        </IconButton>
        <Typography fontFamily={"Syncopate"} fontSize={18} fontWeight={700}>
          {state}
        </Typography>
        <IconButton onClick={() => func(state + 1)}>
          <AddCircleOutlineOutlinedIcon />
        </IconButton>
      </Box>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Typography fontFamily={"Syncopate"}>{euroFormat(unityPrice*state)}</Typography>
				<IconButton onClick={()=>func(0)}>
					<DeleteIcon />
				</IconButton>
      </Box>
    </>
  );
};

export default TicketCounter;
