import React from "react";
import logo from "../assets/img/logo.svg";

const Header = () => {
  return (
    <div style={{ width: "auto", padding: "8px" }}>
      <a href="https://amberprize.com/">
        <img src={logo} alt="logo" width={60} height={21} />
      </a>
    </div>
  );
};

export default Header;
