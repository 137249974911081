import { Button, Typography } from '@mui/material';
import React from 'react';
import "@fontsource/syncopate/400.css";
import "@fontsource/syncopate/700.css";

const BuyButton = ({setState}) => {
	return (
    <Button
      fontFamily={"Syncopate"}
      onClick={() => setState(1)}
      size="medium"
      sx={{
        backgroundColor: "#ff1717",
        ":hover": { backgroundColor: "#ff1717" },
        px: "2rem",
        borderRadius: "25px",
        py: "0.5rem",
      }}
    >
      <Typography
        fontFamily={"Syncopate"}
        textTransform={"lowercase"}
        fontWeight={700}
        color={"#fff"}
      >
        Buy now
      </Typography>
    </Button>
  );
};

export default BuyButton;
