import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from 'axios';
import LoadingButton from "@mui/lab/LoadingButton";
import { navigate } from "@reach/router";


export default function CheckoutDialog({ open, handleClose, qt1, qt2, discount }) {
  const [name, setName] = useState(localStorage.getItem('name') || "");
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [agency, setAgency] = useState(localStorage.getItem("agency") || "");
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem("phoneNumber") || "");
  const [companyDetails, setCompanyDetails] = useState(localStorage.getItem("companyDetails") || "");
	const [referenceNumber, setReferenceNumber] = useState(
    localStorage.getItem("referenceNumber") || ""
  );
  const [policy, setPolicy] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
	const [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {
    if (name && email && agency && phoneNumber && companyDetails && policy) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    return null;
  }, [name, email, agency, phoneNumber, companyDetails, policy]);

	const handleSubmit = async() => {
    setIsLoading(true)
    const urlDev =
      "http://localhost:5001/amberprize-3f613/us-central1/getPdfUrl";
    const url =
      "https://us-central1-amberprize-3f613.cloudfunctions.net/getPdfUrl";
    const res = await axios.post(
      url,
      {},
      {
        responseType: "blob",
        headers: {
          email,
          name,
          "phone-number": phoneNumber,
          agency,
          "company-details": companyDetails,
          qt1,
          qt2,
          discount,
          "reference-number": referenceNumber,
        },
      }
    );
    const url2 = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url2;
    link.setAttribute("download", "invoice.pdf");
    document.body.appendChild(link);
    link.click();
    setIsLoading(false)
		setIsSubmited(true)
		handleClose()
    localStorage.clear()
    navigate("http://amberprize.com/");
	}

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Checkout</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={<Checkbox />}
            value={policy}
            checked={policy}
            onChange={(e) => setPolicy(!policy)}
            label={
              <div>
                <span>By sending this form I agree with </span>
                <a href="https://amberprize.com/policy">Privacy Policy</a>
              </div>
            }
          />
          <TextField
            margin="dense"
            required
            id="name"
            label="Your Name"
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              localStorage.setItem("name", e.target.value);
            }}
          />
          <TextField
            margin="dense"
            required
            id="agency"
            label="Agency"
            type="text"
            fullWidth
            variant="outlined"
            value={agency}
            onChange={(e) => {
              setAgency(e.target.value);
              localStorage.setItem("agency", e.target.value);
            }}
          />
          <TextField
            margin="dense"
            required
            id="email"
            label="E-mail Address"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              localStorage.setItem("email", e.target.value);
            }}
          />
          <TextField
            margin="dense"
            required
            id="phoneNumber"
            label="Phone Number"
            type="text"
            fullWidth
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              localStorage.setItem("phoneNumber", e.target.value);
            }}
          />
          <TextField
            margin="dense"
            required
            id="companyDetails"
            label="Company details to be billed"
            type="text"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            value={companyDetails}
            onChange={(e) => {
              setCompanyDetails(e.target.value);
              localStorage.setItem("companyDetails", e.target.value);
            }}
          />
          <TextField
            margin="dense"
            id="companyDetails"
            label="Your reference number"
            type="text"
            fullWidth
            variant="outlined"
            value={referenceNumber}
            onChange={(e) => {
              setReferenceNumber(e.target.value);
              localStorage.setItem("referenceNumber", e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            variant="contained"
            size="large"
            disabled={!isValid}
            sx={{
              backgroundColor: "#000",
              padding: "1rem 4rem",
              ":hover": { backgroundColor: "#333" },
            }}
            onClick={handleSubmit}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
