import { Link } from '@mui/material';
import React from 'react';
import "@fontsource/syncopate/400.css";
import "@fontsource/syncopate/700.css";

const LinkFooter = ({ href, label, target = "_self" }) => {
  return (
    <Link
      fontFamily={"Syncopate"}
      href={href}
      color="#dbdbdb"
      variant="body2"
      underline="none"
      mb={1.25}
      target={target}
    >
      {label.toUpperCase()}
    </Link>
  );
};

export default LinkFooter;
